exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* ------------------------\nCommon\n--------------------------*/\n\n/* layouts width*/\n\n/* ------------------------\nDark Theme\n--------------------------*/\n\n/* colors*/\n\n/* input*/\n\n/* modal content padding*/\n\n/* ------------------------\nLight Theme\n--------------------------*/\n\n/* colors*/\n\n/* modal content padding*/\n\n/* ------------------------\nRating\n--------------------------*/\n\n.card-30x7X {\n  padding: 26px 20px;\n  background-color: #042b75;\n  border: solid 1px #001240;\n}\n\n.card-30x7X:not(:last-child) {\n    margin-bottom: 10px;\n  }\n\n.card-30x7X.warn-_lQ08 {\n    background-color: rgba(255, 109, 127, 0.24) !important;\n    border: solid 1px #ff6d7f;\n  }\n\n.row-1LyNx {\n  line-height: 1.75rem;\n}\n\n.nameContainer-2Tora {\n  -webkit-box-align: start;\n      -ms-flex-align: start;\n          align-items: flex-start;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n}\n\n.tooltipContainer-231fz {\n  display: inline-block;\n}\n\n.tooltipContainer-231fz .tooltip-1uELU, .tooltipContainer-231fz:hover .tooltip-1uELU {\n      line-height: normal;\n      max-width: 250px;\n      width: -webkit-max-content;\n      width: -moz-max-content;\n      width: max-content;\n    }\n\n.warnIcon-2cYm2 {\n  color: #ff6d7f;\n  font-size: 20px;\n  margin-right: 10px;\n}\n\n.name-3dSoS {\n  margin-bottom: 0;\n}\n\n.deleteButton-1i2Pe {\n  text-align: right;\n}\n\n.theme-dark .card-30x7X {\n    background-color: #042b75;\n  }\n\n.theme-light .card-30x7X {\n    background-color: #fff;\n  }\n", ""]);

// exports
exports.locals = {
	"card": "card-30x7X",
	"warn": "warn-_lQ08",
	"row": "row-1LyNx",
	"nameContainer": "nameContainer-2Tora",
	"tooltipContainer": "tooltipContainer-231fz",
	"tooltip": "tooltip-1uELU",
	"warnIcon": "warnIcon-2cYm2",
	"name": "name-3dSoS",
	"deleteButton": "deleteButton-1i2Pe"
};