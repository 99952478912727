exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".container-3Vf5p {\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n  margin: 0 -20px;\n  padding: 0;\n}\n\n.radio-2VjyW {\n  margin: 0 20px 20px;\n}\n", ""]);

// exports
exports.locals = {
	"container": "container-3Vf5p",
	"radio": "radio-2VjyW"
};