import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-flexbox-grid';
import cn from 'classnames';

import { Button, LabelText, H, Link, Icon, Tooltip } from 'components/ui';
import { makeFormattedDate } from 'magic/date';
import { noop } from 'utils/lodash';

import { ROUTES_WAYS } from 'constants/routesWays';
import css from './ProfileCard.scss';

const ProfileCard = ({ birthDate, fio, id, rfsId, onDelete, warn }) => {
  const handleDelete = useCallback(() => onDelete(id), [id, onDelete]);

  const cardStyles = useMemo(
    () =>
      cn({
        [css.card]: true,
        [css.warn]: !!warn,
      }),
    [warn],
  );

  return (
    <div className={cardStyles}>
      <Row className={css.row}>
        <Col md={3}>
          <LabelText label="РФС ID (ЕНИ)" text={rfsId} />
        </Col>
        <Col className={css.nameContainer} md={4}>
          {warn && (
            <Tooltip containerClassName={css.tooltipContainer} className={css.tooltip} text={warn}>
              <Icon className={css.warnIcon} icon="exclamation-triangle" pack="fas" />
            </Tooltip>
          )}
          <H className={css.name} size={3}>
            <Link to={ROUTES_WAYS.PROFILE_BY_ID.replace(':id', id)}>{fio}</Link>
          </H>
        </Col>
        <Col md={3}>
          <LabelText label="Дата рождения" text={makeFormattedDate(birthDate)} />
        </Col>
        <Col className={css.deleteButton} md={2}>
          <Button onClick={handleDelete} type="text" icon="trash">
            Удалить
          </Button>
        </Col>
      </Row>
    </div>
  );
};

ProfileCard.propTypes = {
  birthDate: PropTypes.number,
  fio: PropTypes.string,
  id: PropTypes.number,
  onDelete: PropTypes.func,
  rfsId: PropTypes.number,
  warn: PropTypes.string,
};

ProfileCard.defaultProps = {
  birthDate: undefined,
  fio: '',
  id: undefined,
  onDelete: noop,
  rfsId: undefined,
  warn: undefined,
};

export default React.memo(ProfileCard);
