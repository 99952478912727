/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';

import { DateTimePicker } from 'components/rfs-ui';
import { isFunction } from 'utils/lodash';

import { PTInput } from '../propTypes';

const DateTimePickerComponent = ({ input, meta, onChange, ...props }) => {
  const handleChange = useCallback(
    (data) => {
      input.onChange(data);
      if (isFunction(onChange)) {
        onChange(data);
      }
    },
    [onChange, input],
  );

  return <DateTimePicker {...props} onChange={handleChange} value={input.value} error={meta.error} isInvalid={!!meta.error} />;
};

DateTimePickerComponent.propTypes = {
  ...DateTimePicker.propTypes,
  ...PTInput,
};

export default DateTimePickerComponent;
