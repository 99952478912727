/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';

import { Checkbox } from 'components/rfs-ui';
import { isFunction } from 'utils/lodash';

import { PTInput } from '../propTypes';

const CheckboxComponent = ({ input, meta, onChange, ...props }) => {
  const handleChange = useCallback(
    (data) => {
      input.onChange(data);
      if (isFunction(onChange)) {
        onChange(data);
      }
    },
    [onChange, input],
  );

  return <Checkbox {...props} onChange={handleChange} checked={input.value || false} />;
};

CheckboxComponent.propTypes = {
  ...Checkbox.propTypes,
  ...PTInput,
};

export default CheckboxComponent;
