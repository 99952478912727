/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';

import { Toggle } from 'components/rfs-ui';
import { isFunction } from 'utils/lodash';

import { PTInput } from '../propTypes';

const ToggleComponent = ({ input, meta, onChange, ...props }) => {
  const checked = props.checked;

  const handleChange = useCallback(
    (data) => {
      input.onChange(data);
      if (isFunction(onChange)) {
        onChange(data);
      }
    },
    [onChange, input],
  );

  return <Toggle {...props} onChange={handleChange} checked={checked ? checked : input.value} />;
};

ToggleComponent.propTypes = {
  ...Toggle.propTypes,
  ...PTInput,
};

export default ToggleComponent;
