/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Select } from 'components/rfs-ui';
import { isFunction } from 'utils/lodash';

import { PTInput } from '../propTypes';

const SelectComponent = ({ input, meta, onChange, ...props }) => {
  const handleChange = useCallback(
    (data) => {
      input.onChange(data);
      if (isFunction(onChange)) {
        onChange(data);
      }
    },
    [onChange, input],
  );

  return <Select {...props} onChange={handleChange} value={input.value} />;
};

SelectComponent.propTypes = {
  ...Select.propTypes,
  ...PTInput,
  labelKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.array]),
  onChange: PropTypes.func,
  valueKey: PropTypes.string,
};

SelectComponent.defaultProps = {
  labelKey: 'label',
  onChange: undefined,
  valueKey: 'value',
};

export default SelectComponent;
