/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Field } from 'react-final-form';

import RadioComponent from './RadioComponent';
import { PTField } from '../propTypes';

const Radio = ({ title, value, disabled, ...props }) => (
  <Field {...props} optionValue={value} optionTitle={title} disabled={disabled} component={RadioComponent} />
);

Radio.propTypes = {
  ...RadioComponent.propTypes,
  ...PTField,
};

export default Radio;
