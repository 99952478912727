/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { DocumentUpload } from 'components/rfs-ui';
import { PTInput } from '../propTypes';

const DocumentUploadComponent = ({ input, meta, ...props }) => {
  const value = useMemo(() => (Array.isArray(input.value) ? input.value : []), [input.value]);

  return <DocumentUpload {...props} onChange={input.onChange} documents={value} />;
};

DocumentUploadComponent.propTypes = {
  ...DocumentUpload.propTypes,
  ...PTInput,
  onChange: PropTypes.func,
};

DocumentUploadComponent.defaultProps = {
  onChange: undefined,
};

export default DocumentUploadComponent;
