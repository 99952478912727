/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';

import { AsyncSearch } from 'components/rfs-ui';
import { isFunction } from 'utils/lodash';
import { PTInput } from '../propTypes';

const AsyncSearchComponent = ({ input, meta, onChange, ...props }) => {
  const handleChange = useCallback(
    (value) => {
      input.onChange(value);
      if (isFunction(onChange)) {
        onChange(value);
      }
    },
    [onChange, input],
  );

  return <AsyncSearch {...props} onChange={handleChange} value={input.value || null} />;
};

AsyncSearchComponent.propTypes = {
  ...AsyncSearch.propTypes,
  ...PTInput,
};

export default AsyncSearchComponent;
