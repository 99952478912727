/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Radio from '../Radio';
import css from './RadioGroup.scss';

const RadioGroup = ({ prefixId, name, options, className, ...props }) => {
  const styles = useMemo(() => cn(css.container, className), [className]);

  return (
    <fieldset className={styles}>
      {options.map((option) => (
        <Radio
          {...props}
          className={css.radio}
          id={`${prefixId ? `${prefixId}-` : ''}${name}-${option.value}`}
          name={name}
          value={option.value}
          title={option.label}
          key={option.value}
          disabled={option.disabled}
          labelClass={option.labelClass}
        />
      ))}
    </fieldset>
  );
};

const PTOption = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

RadioGroup.propTypes = {
  ...Radio.propTypes,
  className: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape(PTOption)),
  prefixId: PropTypes.string,
};

RadioGroup.defaultProps = {
  className: undefined,
  options: [],
  prefixId: '',
};

export default RadioGroup;
