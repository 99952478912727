/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Field } from 'react-final-form';

import ToggleComponent from './ToggleComponent';
import { PTField } from '../propTypes';

const Toggle = (props) => <Field {...props} component={ToggleComponent} />;

Toggle.propTypes = {
  ...ToggleComponent.propTypes,
  ...PTField,
};

export default Toggle;
