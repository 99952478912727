/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import { Field } from 'react-final-form';

import { isEmpty } from 'utils/lodash';

import DocumentUploadComponent from './DocumentUploadComponent';
import { PTField } from '../propTypes';

/**
 * @type {React.FC<any>}
 */
const DocumentUpload = (props) => {
  const { validate, required } = props;

  const validateField = useMemo(() => {
    if (required && isEmpty(validate)) {
      return (value) => isEmpty(value) && 'Обязательное поле';
    }

    return validate;
  }, [validate, required]);

  return <Field {...props} validate={validateField} component={DocumentUploadComponent} />;
};

DocumentUpload.propTypes = {
  ...DocumentUploadComponent.propTypes,
  ...PTField,
};

export default DocumentUpload;
