import React, { useMemo, useRef } from 'react';
import JoditEditor from 'jodit-react';

export const EditorComponent = ({ name, value, onChange, placeholder = 'Начните вводить текст...' }: {
  name: string;
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
}) => {
  const editor = useRef(null);

  const config = useMemo(() => ({
      readonly: false,
      placeholder: placeholder,
      removeButtons: ['classSpan', 'file', 'image', 'video', 'spellcheck', 'speechRecognize', 'copyformat', 'symbols', 'ai-commands', 'ai-assistant'],
  }), [placeholder]);

  const handleChange = (newContent: string) => {
    onChange(newContent);
  };

  return (
    <JoditEditor
      name={name}
      ref={editor}
      value={value}
      config={config}
      tabIndex={1}
      onChange={handleChange}
    />
  );
}
