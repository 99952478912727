exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".container-3XHpo {\n  background-color: #042b75;\n  border: solid 1px #001240;\n  padding: 20px;\n  margin-bottom: 40px;\n}\n\n.collapseContainer-OCeie {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n}\n\n.filters-1yX3W {\n  margin-bottom: -20px;\n}\n\n.buttonsContainer-1ID27 {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  margin-top: 40px;\n}\n\n.buttonsContainer-1ID27 button {\n    width: 300px;\n  }\n\n.withCollapse-xcVBE {\n  margin-bottom: 40px;\n}\n", ""]);

// exports
exports.locals = {
	"container": "container-3XHpo",
	"collapseContainer": "collapseContainer-OCeie",
	"filters": "filters-1yX3W",
	"buttonsContainer": "buttonsContainer-1ID27",
	"withCollapse": "withCollapse-xcVBE"
};