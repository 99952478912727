/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Field } from 'react-final-form';

import TimePickerComponent from './TimePickerComponent';
import { PTField } from '../propTypes';

const TimePicker = (props) => <Field {...props} component={TimePickerComponent} />;

TimePicker.propTypes = {
  ...TimePickerComponent.propTypes,
  ...PTField,
};

export default TimePicker;
