/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Radio } from 'components/rfs-ui';
import { isFunction } from 'utils/lodash';
import { PTInput } from '../propTypes';

const RadioComponent = ({ input, meta, optionValue, optionTitle, onChange, disabled, labelClass, ...props }) => {
  const handleChange = useCallback(
    (event) => {
      input.onChange(event.target.value);
      if (isFunction(onChange)) {
        onChange(event.target.value);
      }
    },
    [onChange, input],
  );

  return (
    <Radio
      {...props}
      disabled={disabled}
      title={optionTitle}
      value={optionValue}
      checked={input.value === optionValue}
      onChange={handleChange}
      labelClass={labelClass}
    />
  );
};

RadioComponent.propTypes = {
  ...Radio.propTypes,
  ...PTInput,
  optionTitle: PropTypes.string.isRequired,
  optionValue: PropTypes.string.isRequired,
};

export default RadioComponent;
