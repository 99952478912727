import React, { useState } from 'react';
import { ReleasesItemDto } from 'propTypes/ReleasesType';
import { ReleasesItemEdit } from './edit/ReleasesItemEdit';
import { ReleasesItemView } from './view/ReleasItemView';

interface ReleasesItemProp {
  data: ReleasesItemDto;
  onSave: () => void;
  onDelete: () => void;
  onPublish: () => void;
  isAccess: boolean;
}

export const ReleasesItem = ({ data, onSave, onDelete, onPublish, isAccess }: ReleasesItemProp) => {
  const [isEdit, setIsEdit] = useState(false);

  const handleSave = () => {
    setIsEdit(false);
    onSave();
  }

  return (
    <>
      {isEdit ?
        <ReleasesItemEdit
          id={data.id}
          isEdit={isEdit}
          onReject={() => setIsEdit(false)}
          onSave={handleSave}
        /> :
        <ReleasesItemView
          data={data}
          onEdit={() => setIsEdit(true)}
          onDelete={onDelete}
          onPublish={onPublish}
          isAccess={isAccess}
        />
      }
    </>
  )
};
