import React from 'react';
import PropTypes from 'prop-types';
import PaginationRow from './PaginationRow';

class Pagination extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    onPaginationChange: PropTypes.func,
  };

  handleItemsPerPageChange = (perPage) => () => {
    this.props.onPaginationChange({ perPage, page: 0 });
  };

  handlePageChange = (page) => {
    this.props.onPaginationChange({ perPage: this.props.perPage, page: page.selected });
  };

  render() {
    const { children, onPaginationChange, ...paginationProps } = this.props;

    return (
      <React.Fragment>
        <PaginationRow {...paginationProps} onPageChange={this.handlePageChange} onItemsPerPageChange={this.handleItemsPerPageChange} />
        {children}
        <PaginationRow {...paginationProps} onPageChange={this.handlePageChange} onItemsPerPageChange={this.handleItemsPerPageChange} />
      </React.Fragment>
    );
  }
}

export default Pagination;
