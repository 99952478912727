/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Field } from 'react-final-form';

import InputComponent from './InputComponent';
import { PTField } from '../propTypes';

/**
 * @type {React.FC<any>}
 */
const Input = (props) => <Field {...props} component={InputComponent} />;

Input.propTypes = {
  ...InputComponent.propTypes,
  ...PTField,
};

export default Input;
