/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Field } from 'react-final-form';

import TextareaComponent from './TextareaComponent';
import { PTField } from '../propTypes';

const Textarea = (props) => <Field {...props} component={TextareaComponent} />;

Textarea.propTypes = {
  ...TextareaComponent.propTypes,
  ...PTField,
};

export default Textarea;
