import React from 'react';
import { Field, FieldProps} from 'react-final-form';
import { EditorComponent } from './Editor';

const Editor = ({name, required, fieldProps}: {
  name: string;
  required?: boolean;
  fieldProps?: Partial<FieldProps<any, any>>;
}) => {

  const isRequired = (value: string) => {
    if (!required) {
      return undefined;
    }
    return value ? undefined : 'Required';
  };

  return (
    <Field
      name={name}
      validate={isRequired}
      render={({ input: { name, value, onChange } }) => (
        <EditorComponent
          name={name}
          value={value}
          onChange={onChange}
        />
      )}
      {...fieldProps}
    />
  );
}

export default Editor;
