exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".filtersContainer-1quha {\n  margin-top: 20px;\n}\n  .filtersContainer-1quha .filters-2UxNV {\n    display: grid;\n    grid-auto-flow: column;\n    grid-column-gap: 20px;\n  }\n", ""]);

// exports
exports.locals = {
	"filtersContainer": "filtersContainer-1quha",
	"filters": "filters-2UxNV"
};