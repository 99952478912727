/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import { Field } from 'react-final-form';

import { isEmpty } from 'utils/lodash';

import FileUploadComponent from './FileUploadComponent';
import { PTField } from '../propTypes';

const FileUpload = ({ type, multiple, ...props }) => {
  const { validate, required } = props;

  const validateField = useMemo(() => {
    if (required && isEmpty(validate)) {
      return (value) => isEmpty(value) && 'Обязательное поле';
    }

    return validate;
  }, [validate, required]);

  return <Field {...props} inputType={type} inputMultiple={multiple} validate={validateField} component={FileUploadComponent} />;
};

FileUpload.propTypes = {
  ...FileUploadComponent.propTypes,
  ...PTField,
};

export default FileUpload;
