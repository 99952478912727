/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import dadataEndpoints from 'constants/dadataEndpoints';
import { legalRestructure } from 'utils/dadata';
import { isEmpty } from 'utils/lodash';

import DadataField from './DadataField';

const parseLegal = (value) => (isEmpty(value) ? null : legalRestructure(value));

const LegalField = (props) => <DadataField url={dadataEndpoints.legal} parse={parseLegal} {...props} />;

export default LegalField;
