/**
 * @enum PERMISSIONS
 * @example https://gost-jira.atlassian.net/wiki/spaces/RFSEAIS/pages/791446257/I.b.
 */

export const PERMISSIONS = Object.freeze({
  /**
   * @description addAffiliationContract - Добавление аффилированных юридических лиц
   */
  ADD_AFFILIATION_CONTRACT: 'addAffiliationContract',
  /**
   * @description addOrg - Добавить футбольную организацию с выбором "вышестоящей организации" своей и подчиненных
   */
  ADD_ORGANIZATION: 'addOrg',
  /**
   * @description addParticipant - Добавить участника в свою или подчиненную организацию
   */
  ADD_PARTICIPANT: 'addParticipant',
  /**
   * @description adminHistoryFut - Добавление, удаление и редактирование записей в истории переходов футболистов
   */
  ADMIN_HISTORY_FUT: 'adminHistoryFut',
  /**
   * @description blockAndUnblock - Блокировка и разблокировка учетных записей. Просмотр статуса учетных записей и истории блокировок
   */
  BLOCK_AND_UNBLOCK: 'blockAndUnblock',
  /**
   * @description sertificationStadium - Управление сертификатом в карточках стадионов и манежей - выдача, приостановление, возобновление и редактирование
   */
  CERTIFICATION_STADIUM: 'sertificationStadium',
  /**
   * @description checkStatmentSertification - Доступ к разделу "Заявления на сертификацию". Просмотр всех входящих заявлений на сертификацию стадионов
   */
  CHECK_STATEMENT_CERTIFICATION: 'checkStatmentSertification',
  /**
   * @description ContractConclusion - Право, по которому сотрудник клуба может инициировать заключение контракта
   */
  CONTRACT_CONCLUSION: 'ContractConclusion',
  /**
   * @description controlCatalog - Управление справочниками модулей "Паспортизация" и "Управление соревнованиями"
   */
  CONTROL_CATALOG: 'controlCatalog',
  /**
   * @description controlClubCategory - Управление категориями клубов и школ (в рамках всей Системы)
   */
  CONTROL_CLUB_CATEGORY: 'controlClubCategory',
  /**
   * @description controlClubLicense - Управление лицензиями клубов
   */
  CONTROL_CLUB_LICENSE: 'controlClubLicense',
  /**
   * @description controlFifaConnect - управление Fifa connect
   */
  CONTROL_FIFA_CONNECT: 'controlFifaConnect',
  /**
   * @description controlFootStatus - Данное право позволяет ставить на учет/снимать с учета/ менять статус/
   * переводить футболиста из единого функционального окна УП для админов
   */
  CONTROL_FOOT_STATUS: 'controlFootStatus',
  /**
   * @description controlGuide - Просмотр, создание и редактирование справочников
   */
  CONTROL_GUIDE: 'controlGuide',
  /**
   * @description controlRolePermission - Данное право позволяет управлять фильтрами прав доступа в рамках всей системы
   */
  CONTROL_ROLE: 'controlRolePermission',
  /**
   * @description createAccount - Создание учетной записи
   */
  CREATE_ACCOUNT: 'createAccount',
  /**
   * @description createAccountAdmin - Создание учетных записей в рамках всей системы
   */
  CREATE_ACCOUNT_ADMIN: 'createAccountAdmin',
  /**
   * @description createAndEditClub - Создание и редактирование клуба
   */
  CREATE_AND_EDIT_CLUB: 'createAndEditClub',
  /**
   * @description createAndEditInfrastructure - Создание новых объектов футбольной инфраструктуры в системе.
   * Редактирование всех объектов футбольной инфраструктуры в системе
   */
  CREATE_AND_EDIT_INFRASTRUCTURE: 'createAndEditInfrastructure',
  /**
   * @description createAndEditPersonLogin - В рамках данного права администратор может добавлять логин для
   * синхронизации с другими модулями ЦП для участников без учётной записи в Паспортизации
   */
  CREATE_AND_EDIT_PERSON_LOGIN: 'createAndEditPersonLogin',
  /**
   * @description createAndEditSportObject - Создание новый объектов, кроме типа Стадион и привязка к юр.лицу
   * (через собственника или арендатора), в котором текущий пользователь имеет место работы
   */
  CREATE_AND_EDIT_SPORT_OBJECT: 'createAndEditSportObject',
  /**
   * @description createStatmentSertification - Подача заявления на сертификацию объекта футбольной инфраструктуры
   * типа стадион и манеж. Просмотр исходящих заявлений пользователя на сертификацию
   */
  CREATE_CERTIFICATION_STATEMENT: 'createStatmentSertification',
  /**
   * @description createAndEditStudy - Создание и редактирование  упражнения / теста
   */
  CREATE_EDIT_STUDY: 'createAndEditStudy',
  /**
   * @description CreateAndEditTeam - Создание/Редактирование сборной
   */
  CREATE_EDIT_TEAM: 'CreateAndEditTeam',
  /**
   * @description createLegal - Создание локального юридического лица и организации
   */
  CREATE_LEGAL: 'createLegal',
   /**
   * @description createExternalLegal - Создание международного юридического лица и организации
   */
   CREATE_EXTERNAL_LEGAL: 'createExternalLegal',
  /**
   * @description bankDetailsView - Просмотр банковских реквизитов международного БЛ
   */
    BANK_DETAILS_VIEW: 'bankDetailsView',
  /**
   * @description createProfile - Создание профиля без редактирования, а также редактирование основных сведений, фото
   */
  CREATE_PROFILE: 'createProfile',
  /**
   * @description createProfAndSportActivity -  По праву доступна возможность добавления, редактирования, завершения проф.деятельности
   * и спорт.деятельности в рамках иерархии организаций
   */
  CREATE_PROF_AND_SPORT_ACTIVITY: 'createProfAndSportActivity',
  /**
   * @description createStatmentListCompetition - Формирование/редактирование ЗЛ заявочного листа на турнир
   */
  CREATE_STATEMENT_COMPETITION_LIST: 'createStatmentListCompetition',
  /**
   * @description delegateControlCoachGroup - Делегирование управления группой подготовки, назначение ответственных лиц.
   * Пользователь должен быть связан с организацией
   */
  DELEGATE_CONTROL_COACH_GROUP: 'delegateControlCoachGroup',
  /**
   * @description DocTypeConstructor - Право на открытие конструктора документов
   */
  DOCUMENT_TYPE_CONSTRUCTOR: 'DocTypeConstructor',
  /**
   * @description doAppeal - Доступ к созданию обращений
   */
  DO_APPEAL: 'doAppeal',
  /**
   * @description editAndViewRights - Просмотр и редактирование прав доступа в профилях участников, которые состоят в организации
   * пользователя или в подчиненных организациях
   */
  EDIT_AND_VIEW_RIGHTS: 'editAndViewRights',
  /**
   * @description editCoachGroup - Редактирование тренировочных групп. Планирование модулей и событий в календаре школы,
   * формирование планов и итогов событий.
   */
  EDIT_COACH_GROUP: 'editCoachGroup',
  /**
   * @description EditFootStatus - Доступ к функциональному окну "Управление переходами любителей"
   * и работа с футболистами-любителями,которые состоят в организации пользователя или в подчиненных организациях
   */
  EDIT_FOOT_STATUS: 'EditFootStatus',
  /**
   * @description editMatchReferee - Редактирование карточки матча, формирование протокола и одобрение списка участников на матч.
   * Пользователь должен быть назначен судьей на матч
   */
  EDIT_MATCH_REFEREE: 'editMatchReferee',
  /**
   * @description editPersonalData - Данное право позволяет пользователю загружать/удалять/изменять документы, которые находятся
   * на странице "Персональные данные" в режиме управления
   */
  EDIT_PERSONAL_DATA: 'editPersonalData',
  /**
   * @description editProfile - По праву доступна возможность добавления, редактирования, удаления Контактов,
   * Документов, Образования, Заявления на регистрацию, Разрядов и званий футболистов, Категорий тренеров и судей,
   * Лицензий тренеров в рамках иерархии организаций
   */
  EDIT_PROFILE: 'editProfile',
  /**
   * @description editProfileAdmin - Позволяет редактировать все сведения любого участника, за исключением фото и основной информации
   */
  EDIT_PROFILE_ADMIN: 'editProfileAdmin',
  /**
   * @description editProfileProfPlayer - Просмотр и редактирование расширенных сведений профиля футболиста-профессионала
   */
  EDIT_PROFILE_PROF_PLAYER: 'editProfileProfPlayer',
  /**
   * @description exportPlayerIntPassport - Позволяет скачивать спортивный и международный паспорта у футболистов) (в рамках организации)
   */
  EXPORT_PLAYER_INT_PASSPORT: 'exportPlayerIntPassport',
  /**
   * @description exportPlayerPassport - Позволяет скачивать футбольный паспорт у футболистов (в рамках организации)
   */
  EXPORT_PLAYER_PASSPORT: 'exportPlayerPassport',
  /**
   * @description exportPlayerPassportAdmin - Позволяет скачивать футбольный, спортивный и международный паспорта у футболистов (в рамках системы)
   */
  EXPORT_PLAYER_PASSPORT_ADMIN: 'exportPlayerPassportAdmin',
  /**
   * @description findInfrastructure - Доступ к пункту левого меню "Футбольные объекты"
   */
  FIND_INFRASTRUCTURE: 'findInfrastructure',
  /**
   * @description findOrg - Доступ к пункту левого меню "Организации"
   */
  FIND_ORGANIZATION: 'findOrg',
  /**
   * @description findParticipant - Доступ к пункту левого меню "Участники"
   */
  FIND_PARTICIPANT: 'findParticipant',
  /**
   * @description getAppeal - Доступ к конструктору обращений, просмотр и обработка входящих обращений
   */
  GET_APPEAL: 'getAppeal',
  /**
   * @description GetTransferStatistic - Получение статистики переходов в клубе за период
   */
  GET_TRANSFER_STATISTIC: 'GetTransferStatistic',
  /**
   * @description IntegrationWithEducationByLogin - Добавление образования по логину (в рамках системы)
   */
  INTEGRATION_WITH_EDUCATION_BY_LOGIN: 'IntegrationWithEducationByLogin',
  JURIS_SECRETARY: 'JurisSecretary',
  LEAGUE_JUDICIARY_DECISION: 'ligaJudiciaryDecision',
  JUDICIARY_DECISION : 'JudiciaryDecision',
  /**
   * @description payCalc - Право, в рамках системы, по которому сотрудник может вызывать и использовать калькулятор выплат.
   */
  PAY_CALCULATOR: 'payCalc',
  /**
   * @description payConstructor - Право, в рамках системы, по которому сотрудник может вызывать и делать
   * необходимые настройки в конструкторе выплат.
   */
  PAY_CONSTRUCTOR: 'payConstructor',
  /**
   * @description rateMatchPlayersCoach - Добавление/Редактирование/Просмотр соревновательной оценки футболиста по матчу тренером
   */
  RATE_MATCH_PLAYERS_COACH: 'rateMatchPlayersCoach',
  /**
   * @description rateMatchPlayersMethodist - Добавление/Редактирование/Просмотр соревновательной оценки футболиста по матчу методистом
   */
  RATE_MATCH_PLAYERS_METHODIST: 'rateMatchPlayersMethodist',
  /**
   * @description removalStatement - Просмотр и обработка заявлений от футболистов и организаций на снятие
   * футболиста с учета или его перевода в другой клуб, который состоит в организации пользователя или в
   * подчиненных организациях
   */
  REMOVAL_STATEMENT: 'removalStatement',
  /**
   * @description editAuthType - Изменение факторов аутентификации
   */
  TWO_FACTOR_AUTHENTICATION: 'editAuthType',
  /**
   * @description updateControlIndicator - Расчет фактических показателей контрольных индикаторов
   */
  UPDATE_CONTROL_INDICATOR: 'updateControlIndicator',
  /**
   * @description verificationDocs - Верификация документов в профиле участников, которые состоят в
   * организации пользователя или в подчиненных организациях
   */
  VERIFICATION_DOCS: 'verificationDocs',
  /**
   * @description verificationDocsAdmin - Право дает возможность администратору системы верифицировать
   * документы для участников, не привязанных к организации по проф. или спорт.деятельности
   */
  VERIFICATION_DOCS_ADMIN: 'verificationDocsAdmin',
  /**
   * @description viewAllContracts - Просмотр всех атрибутов контракта футболистов во вкладке "Контракты"
   */
  VIEW_ALL_CONTRACTS: 'viewAllContracts',
  /**
   * @description viewAllOnPerson - Просмотр всех данных в профилях участников, которые состоят в организации
   * пользователя или в подчиненных организациях
   */
  VIEW_ALL_ON_PERSON: 'viewAllOnPerson',
  /**
   * @description viewAndEditMyProfile - Просмотр всех вкладок своего профиля кроме прав доступа,
   * редактирование своего профиля: кроме места работы, спорт деятельности и прав доступа,
   * просмотр своей вкладки "Контракты", включая все контракты и атрибуты
   */
  VIEW_AND_EDIT_MY_PROFILE: 'viewAndEditMyProfile',
  /**
   * @description viewCalendarTraining - Право дает возможность просматривать расписание событий с типом = Тренировка и Тестирование в рамках
   * своей организации и нижестоящих организаций
   */
  VIEW_CALENDAR_TRAINING: 'viewCalendarTraining',
  /**
   * @description viewClubChangeStatement - Просмотр исходящих заявлений на переход футболистов
   */
  VIEW_CLUB_CHANGE_STATEMENT: 'viewClubChangeStatement',
  /**
   * @description viewCompetition - Просмотр карточки соревнования
   */
  VIEW_COMPETITION: 'viewCompetition',
  /**
   * @description viewContracts - Просмотр во вкладке "Контракты" всех атрибутов контракта футболистов, которые
   * состоят в организации пользователя или в подчиненных организациях.
   */
  VIEW_CONTRACTS: 'viewContracts',
  /**
   * @description viewhistorychange - Просмотр истории изменений атрибутов объектов системы
   */
  VIEW_HISTORY_CHANGE: 'viewhistorychange',
  /**
   * @description viewInformationSchool - Просмотр оборудования, тренировочных групп, событий и мероприятий и наставников спортивной школы
   */
  VIEW_INFORMATION_SCHOOL: 'viewInformationSchool',
  /**
   * @description viewProfileOrg - Просмотр профиля организации или юр. лица
   */
  VIEW_PROFILE_ORG: 'viewProfileOrg',
  /**
   * @description viewProfileParticipant - Просмотр основных сведений профиля участника
   */
  VIEW_PROFILE_PARTICIPANT: 'viewProfileParticipant',
  /**
   * @description viewSportStatsPlayer - Просмотр статистики игрока? (переход в таланты, наша смена)
   */
  VIEW_SPORT_STATS_PLAYER: 'viewSportStatsPlayer',
  /**
   * @description viewStudy - Просмотр основной информации упражнения / теста
   */
  VIEW_STUDY: 'viewStudy',
  /**
   * @description Верификация документов спортивных организаций юр. лица
   */
  VERIFICATION_DOCS_ORGANIZATION: 'verificationDocsOrganization',
  /**
   * @description Просмотр документов спортивных организаций юр. лица
   */
  VIEW_DOCUMENTS_ORGANIZATION: 'viewDocumentsOrganization',
  /**
   * @description Управление документами спортивных организаций юр. лица
   */
  CONTROL_DOCUMENTS_ORGANIZATION: 'ControlDocumentsOrganization',
  /**
   * @description Администрирование функционала "Регистрация контрактов"
   */
  CONTRACT_ADMIN: 'ContractAdmin',
  /**
   * @description Управление ограничениями и запретами футболиста
   */
  CONTROL_FOOT_LIMIT_BAN: 'controlFootLimitBan',
    /**
   * @description Доступ к новым соревнованиям
   */
    NEW_COMPETITION: 'newCompetition',
   /**
   * @description Доступ к новым соревнованиям
   */
    VIEW_CAREER_BONUS_REGISTRY: 'viewCareerBonusRegistry',
    /**
     * @description Блок Обновления системы
     */
    CONTROL_RELEASES: 'ControlReleases',
});
