/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Dadata } from 'components/rfs-ui';
import { isFunction } from 'utils/lodash';

import { PTInput } from '../propTypes';

const DadataComponent = ({ input, meta, onChange, ...props }) => {
  const value = useMemo(() => {
    const { value: inputValue } = input.value || {};
    return inputValue ? { label: inputValue, value: inputValue } : undefined;
  }, [input.value]);

  const handleChange = useCallback(
    (data) => {
      input.onChange(data);
      if (isFunction(onChange)) {
        onChange(data);
      }
    },
    [onChange, input],
  );

  return <Dadata {...props} onChange={handleChange} value={value} />;
};

DadataComponent.propTypes = {
  onChange: PropTypes.func,
  url: PropTypes.string.isRequired,
  ...PTInput,
};

DadataComponent.defaultProps = {
  onChange: undefined,
};

export default DadataComponent;
