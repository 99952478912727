import * as React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import ReactPaginate from 'react-paginate';
import inRange from 'lodash/inRange';

import { Icon, Button, LabelText } from 'components/ui';
import { DEFAULT_PER_PAGE, ITEMS_COUNTER } from 'constants/pagination';

import styles from './index.scss';

const cn = classnames.bind(styles);

const PaginationRow = ({
   itemsCount,
   perPage,
   currentPage,
   pageCount,
   onPageChange,
   onItemsPerPageChange,
   pagesCounter,
   withPageCounter,
  }) => {
  // номер первого элемента на странице с учетом отображаемого кол-ва элементов на странице
  const firstItemNumber = currentPage === 0 ? 1 : currentPage * perPage + 1;
  // номер второго элемента на странице с учетом отображаемого кол-ва элементов на странице
  const lastItemNumber = currentPage + 1 !== pageCount ? (currentPage + 1) * perPage : itemsCount;
  const showCounter = itemsCount > DEFAULT_PER_PAGE;

  return (
    <div className={cn(styles.container, { rightSide: !showCounter })}>
      {showCounter && (
        <div className={styles.itemsPerPageContainer}>
          <span className={styles.title}>Показать: </span>
          {pagesCounter.map((count, index) => {
            const active = perPage === count || (!pagesCounter.includes(perPage) && inRange(perPage, pagesCounter[index - 1], count));
            const show =
              itemsCount > count || // если кол-во элементов больше текущего счетчика
              inRange(itemsCount, pagesCounter[index - 1] + 1, count + 1) || // если кол-во элементов больше предыдущего счетчика, но меньше или равно текущему
              active;

            return (
              show && (
                <Button
                  type="text"
                  onClick={!active ? onItemsPerPageChange(count >= itemsCount ? itemsCount : count) : undefined}
                  className={cn({ activeOption: active })}
                  key={index}
                >
                  {count}
                </Button>
              )
            );
          })}
        </div>
      )}
      <div className={styles.paginationContainer}>
        {withPageCounter &&
        (showCounter ? (
          <React.Fragment>
            <div className={styles.pageCountLabel}>
              <LabelText label="Результатов" text={`${firstItemNumber} - ${lastItemNumber} из ${itemsCount}`} />
            </div>
            {pageCount >= 2 && (
              <ReactPaginate
                pageCount={pageCount} // общее количество страниц
                pageRangeDisplayed={2} // количество отображаемых чисел между троеточием и текущей страницей
                marginPagesDisplayed={1} // количество чисел, после которых будет отображено троеточие
                previousLabel={<Icon icon="angle-left" />} // иконка для отображения кнопки предыдущей страницы
                nextLabel={<Icon icon="angle-right" />} // иконка для отображения кнопки следующей страницы
                forcePage={currentPage} // текущая страница
                onPageChange={onPageChange} // событие при изменении страницы
                containerClassName={styles.paginator} // класс для ul
                activeClassName={styles.activePage} // класс для выбранной страницы
                disabledClassName={styles.disabledControl} // класс для неактивных переключателей страниц
              />
            )}
          </React.Fragment>
        ) : (
          <LabelText label="Результатов" text={itemsCount} />
        ))}
      </div>
    </div>
  );
};

PaginationRow.propTypes = {
  currentPage: PropTypes.number,
  itemsCount: PropTypes.number,
  onItemsPerPageChange: PropTypes.func,
  onPageChange: PropTypes.func,
  pageCount: PropTypes.number,
  pagesCounter: PropTypes.arrayOf(PropTypes.number),
  perPage: PropTypes.number,
  withPageCounter: PropTypes.bool,
};

PaginationRow.defaultProps = {
  currentPage: undefined,
  itemsCount: undefined,
  onItemsPerPageChange: undefined,
  onPageChange: undefined,
  pageCount: undefined,
  pagesCounter: ITEMS_COUNTER,
  perPage: undefined,
  withPageCounter: true,
};

export default PaginationRow;
