import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import css from '../ReleasesItem.scss'

import { ReleasesForm } from './ReleasesForm';
import { editReleasesCard, fetchReleasesCard } from 'services/releases';
import { handleError } from 'magic/handlers';
import { AnyObject } from 'final-form';
import { ReleasesCardForm } from 'propTypes/ReleasesType';
import { mapperDataReleasesCardDto, mapperDataReleasesCardForm } from '../../mapper';
import { STATUS_NAME } from 'constants/common';

const item = {
  status: STATUS_NAME.DRAFT,
  types: [],
  releaseDate: '',
  releaseVersion: '',
  modules: [],
  description: '',
} as ReleasesCardForm;

interface ReleasesItemProp {
  id?: number;
  isEdit?: boolean;
  onReject: () => void;
  onSave: () => void;
}

export const ReleasesItemEdit = ({
   id,
   isEdit = false,
   onReject,
   onSave,
}: ReleasesItemProp) => {
  const [data, setData] = useState<ReleasesCardForm>();

  useEffect(() => {
    if(id) {
      (async() => {
        try {
          const data = await fetchReleasesCard(id);
          setData(mapperDataReleasesCardForm(data));
        } catch (error) {
          handleError(error as AnyObject);
        }
      })();
    } else {
      setData(item);
      return;
    }
  }, [])

  const handleSubmit = async (value: ReleasesCardForm) => {
    try {
      await editReleasesCard(mapperDataReleasesCardDto(value));
    } catch (error) {
      handleError(error as AnyObject);
    }
    onSave?.();
  }

  const handleReject = () => {
    onReject?.();
  }

  return (
    <div className={css.tile}>
      {data && (
        <Form
          initialValues={data}
          onSubmit={handleSubmit}
          render={({ handleSubmit }) => (
            <ReleasesForm
              isEdit={isEdit}
              onSubmit={handleSubmit}
              onReject={handleReject}
            />
          )}
        />
      )}
    </div>
  )
};
