import AddressField from './AddressField';
import BankField from './BankField';
import DadataField from './DadataField';
import LegalField from './LegalField';

const Dadata = DadataField;
Dadata.Address = AddressField;
Dadata.Bank = BankField;
Dadata.Legal = LegalField;

export default Dadata;
