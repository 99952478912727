import React, { useState, useEffect, useContext } from 'react';
import PortalLayout from 'components/layouts/PortalLayout/PortalLayout';
import H from 'components/ui/H/H';
import { ReleasesItem } from './ReleasesItem';
import { ButtonGroup, Button } from 'components/ui';
import { ReleasesItemEdit } from './ReleasesItem/edit/ReleasesItemEdit';
import Pagination from 'components/rfs-ui/PaginationNew';
import { Filters } from 'components/composed/Filters';
import {
  ITEMS_COUNTER_EXT,
  DEFAULT_PAGE,
} from 'constants/pagination';
import { ReleasesFilter, ReleasesFilterForm, ReleasesItemDto } from 'propTypes/ReleasesType';
import { FilterPage, PageData, PaginationParams } from 'propTypes/commonTypes';
import { FiltersFields } from './FiltersFields';
import { FILTERS_FIELDS_RELEASES } from './consts';
import { fetchReleasesRegistry } from 'services/releases';
import { handleError } from 'magic/handlers';
import { AnyObject } from 'final-form';
import { PageDataDefault } from 'constants/common';
import PermissionsContext from 'components/composed/Permissions/PermissionsContext';
import { checkPermissions } from 'magic/permissions';
import { PERMISSIONS } from 'constants/permissions';
import { STATUS_NAME } from 'constants/common';
import { mapperPadeData } from 'utils/pagination';

export function Releases() {
  const [isCreate, setIsCreate] = useState(false);
  const [data, setData] = useState<{
    data: ReleasesItemDto[];
    pageData: PageData;
  }>();
  const [padeData, setPadeData] = useState<FilterPage>(PageDataDefault);
  const userPermissions = useContext(PermissionsContext);
  const hasAccess = checkPermissions(false, false, 'global', userPermissions, [PERMISSIONS.CONTROL_RELEASES]);

  const getData = async(filters: ReleasesFilter) => {
    try {
      const data = await fetchReleasesRegistry(filters);
      setData(data);
    } catch (error) {
      handleError(error as AnyObject);
    }
  }

  useEffect(() => {
    if (!hasAccess) {
      getData({ ...padeData, status: STATUS_NAME.PUBLISHED });
    }
    getData(padeData);
  }, [])

  const handleSubmitFilters = (filters: ReleasesFilterForm) => {
    getData({
      dateStartFrom: filters?.dateStartFrom,
      dateStartTo: filters?.dateStartTo,
      description: filters?.description,
      status: filters?.status,
      moduleId: filters?.module?.length ? filters?.module.map(item => item.id).join(',') : undefined,
      typeId: filters?.type?.length ? filters?.type?.map(item => item.id).join(',') : undefined,
      ...padeData
    });
  }

  const handleSave = () => {
    getData(padeData);
    setIsCreate(false);
  }

  const onPaginationChange = (params: PaginationParams) => {
    getData(mapperPadeData(params));
    setPadeData(mapperPadeData(params));
  }

  return (
    <PortalLayout>
      <H size={1}>Обновления системы</H>

      <Filters
        filtersFields={FILTERS_FIELDS_RELEASES}
        onSubmit={(filters) => handleSubmitFilters(filters)}
      >
        <FiltersFields />
      </Filters>

      {hasAccess &&
        <ButtonGroup className="btnGroupTop" right>
          <Button
            type="text"
            icon="plus-circle"
            onClick={() => setIsCreate(true)}
            disabled={isCreate}
          >
            Добавить новость
          </Button>
        </ButtonGroup>
      }
      {isCreate &&
        <ReleasesItemEdit
          onReject={() => setIsCreate(false)}
          onSave={handleSave}
        />
      }
      {data?.data.length ? (
        <Pagination
          perPage={padeData.pagesize}
          pagesCounter={ITEMS_COUNTER_EXT}
          currentPage={padeData.page}
          itemsCount={data?.pageData.size}
          pageCount={data?.pageData.countPage}
          onPaginationChange={onPaginationChange}
        >
          {data?.data?.map(item =>
            <ReleasesItem
              data={item}
              key={item?.id}
              onSave={handleSave}
              onDelete={() => getData(padeData)}
              onPublish={() => getData(padeData)}
              isAccess={hasAccess}
            />)}
        </Pagination>
      ) : (
        <p>Нет записей, удовлетворяющих вашему запросу</p>
      )}
    </PortalLayout>
  );
}
