/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import dadataEndpoints from 'constants/dadataEndpoints';
import { bankRestructure } from 'utils/dadata';
import { isEmpty } from 'utils/lodash';

import DadataField from './DadataField';

const parseBank = (value) => (isEmpty(value) ? null : bankRestructure(value));

const BankField = (props) => <DadataField url={dadataEndpoints.bank} parse={parseBank} {...props} />;

export default BankField;
