import { ModulesItem, ReleasesCardDto, ReleasesCardForm, TypesItem } from 'propTypes/ReleasesType';
import { ListItem } from 'propTypes/commonTypes';

export const mapperDataReleasesCardForm = (data: ReleasesCardDto) => {
  return {
    ...data,
    modules: data?.modules?.map((item: ModulesItem) => {
      return {
        ...item,
        name: item.moduleName,
      };
    }),
    types: data?.types?.map((item: TypesItem) => {
      return {
        ...item,
        name: item.typeName,
      };
    }),
  }
}

export const mapperDataReleasesCardDto = (data: ReleasesCardForm) => {
  return {
    ...data,
    modules: data?.modules?.map((item: ListItem & {moduleId?: number}) => {
      return {
        id: item.moduleId ? item.id : undefined,
        moduleName: item.name,
        moduleId: item.moduleId || item.id,
      };
    }),
    types: data?.types?.map((item: ListItem & { typeId?: number }) => {
      return {
        id: item.typeId  ? item.id : undefined,
        typeName: item.name,
        typeId: item.typeId || item.id,
      };
    }),
  }
}
