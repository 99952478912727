exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* ------------------------\nCommon\n--------------------------*/\n\n/* layouts width*/\n\n/* ------------------------\nDark Theme\n--------------------------*/\n\n/* colors*/\n\n/* input*/\n\n/* modal content padding*/\n\n/* ------------------------\nLight Theme\n--------------------------*/\n\n/* colors*/\n\n/* modal content padding*/\n\n/* ------------------------\nRating\n--------------------------*/\n\n.tile-21kB8 {\n  background: #FFFFFF;\n  border: 1px solid #D9DFEA;\n  padding: 40px 30px 20px 30px;\n  margin-bottom: 20px;\n  font-size: 16px;\n  overflow-wrap: break-word;\n}\n\n.body-3y06B {\n  line-height: 20px;\n}\n\n.body-3y06B * {\n    font-size: 16px!important;\n  }\n\n.body-3y06B h1 {\n    color: #39395b;\n    opacity: 0.4;\n    font-weight: 400!important;\n  }\n", ""]);

// exports
exports.locals = {
	"tile": "tile-21kB8",
	"body": "body-3y06B"
};