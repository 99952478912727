/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { NumberInput } from 'components/rfs-ui';
import { PTInput } from '../propTypes';

const InputComponent = ({ input, meta, ...props }) => (
  <NumberInput {...props} onChange={input.onChange} value={input.value} error={meta.error} />
);

InputComponent.propTypes = {
  ...NumberInput.propTypes,
  ...PTInput,
};

export default InputComponent;
