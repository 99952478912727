import React from 'react';
import { useFormState } from 'react-final-form';
import { Col, Row } from 'react-flexbox-grid';
import { FormElement } from 'components/rfs-ui';
import { ButtonGroup, Button } from 'components/ui';
import { DateTimePicker, Input, Reference} from 'components/composed/Form';
import Editor from 'components/composed/Editor';
import { FieldView } from '../../FieldView';
import { makeStatus } from 'utils/status';
import { requiredText } from 'utils/validators';

export const ReleasesForm = ({isEdit, onSubmit, onReject}: {
  isEdit: boolean;
  onSubmit: () => void;
  onReject: () => void;
}) => {
  const {invalid, values } = useFormState();
  const { status, types, modules } = values;

  const handleReject = () => {
    onReject();
  }

  return (
    <>
      <Row>
        <Col md={12}>
          <FieldView label="Статус:" value={makeStatus(status)} />
        </Col>
        <Col md={6}>
          <FormElement title="Тип новости:" required>
            <Reference
              name="types"
              placeholder="Выберите тип новости"
              optionsPath="/api/rest/dictionary/releases/types"
              labelKey="name"
              valueKey="id"
              clearable
              isMulti
              required
            />
          </FormElement>
        </Col>
        <Col md={6}>
          <FormElement title="Дата релиза:" required>
            <DateTimePicker name="releaseDate" required />
          </FormElement>
        </Col>
        <Col md={6}>
          <FormElement title="Номер релиза:" required>
            <Input name="releaseVersion" validate={requiredText} required />
          </FormElement>
        </Col>
        <Col md={6}>
          <FormElement title="Модуль:" required>
            <Reference
              name="modules"
              placeholder="Выберите модуль"
              optionsPath="/api/rest/dictionary/releases/modules"
              labelKey="name"
              valueKey="id"
              clearable
              isMulti
              required
            />
          </FormElement>
        </Col>
        <Col md={12}>
          <FormElement title="Описание релиза:" required>
            <Editor name="description" required />
          </FormElement>
        </Col>
      </Row>

      <ButtonGroup className="btnGroupMargin">
        <Button onClick={onSubmit} disabled={invalid}>{isEdit ? 'Сохранить' : 'Создать'}</Button>
        <Button onClick={handleReject}>Отменить</Button>
      </ButtonGroup>
    </>
  );
}
