/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import { Field } from 'react-final-form';

import { isEmpty, isFunction } from 'utils/lodash';

import PhotoUploadComponent from './PhotoUploadComponent';
import { PTField } from '../propTypes';

const PhotoUpload = (props) => {
  const { validate, required } = props;

  const validateField = useMemo(() => {
    if (required && !isFunction(validate)) {
      return (value) => isEmpty(value) && 'Обязательное поле';
    }

    return validate;
  }, [validate, required]);

  return <Field {...props} validate={validateField} component={PhotoUploadComponent} />;
};

PhotoUpload.propTypes = {
  ...PhotoUploadComponent.propTypes,
  ...PTField,
};

export default PhotoUpload;
