import React, { ReactNode } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Form } from 'react-final-form';
import FiltersContainer from 'components/composed/FiltersContainer';
import css from './Filters.module.scss';
import { ReleasesFilterForm } from 'propTypes/ReleasesType';

export const Filters = ({
  children,
  filtersFields,
  onSubmit,
}:{
  children: ReactNode;
  filtersFields: string[];
  onSubmit: (filters: ReleasesFilterForm) => void;
}) => {

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, form, errors }) => {
        const resetFilters = () => {
          filtersFields.map((item: any) => form.change(item, undefined));
          onSubmit({});
        };

        return (
          <FiltersContainer
            className={css.filtersContainer}
            onApply={handleSubmit}
            onReset={resetFilters}
            disabled={!isEmpty(errors)}
            collapsible
          >
            <div className={css.filters}>
              {children}
            </div>
          </FiltersContainer>
        );
      }}
    />
  )
};
