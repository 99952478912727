/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';

import { Input } from 'components/rfs-ui';
import { isFunction } from 'utils/lodash';
import { PTInput } from '../propTypes';

const InputComponent = ({ input, meta, onChange, buttonIconAfter, ...props }) => {
  const handleChange = useCallback(
    (value) => {
      input.onChange(value);
      if (isFunction(onChange)) {
        onChange(value);
      }
    },
    [onChange, input],
  );

  return (
    <div style={buttonIconAfter ? { display: 'flex', alignItems: 'center', justifyContent: 'space-between' } : {}}>
      <Input {...props} {...input} onChange={handleChange} error={meta.error} valid={!meta.error} />
      {buttonIconAfter && buttonIconAfter}
    </div>
  );
};

InputComponent.propTypes = {
  ...Input.propTypes,
  ...PTInput,
};

export default InputComponent;
