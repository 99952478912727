import { STATUS, STATUS_NAME } from 'constants/common';

export const makeStatus = (status: string) => {
  switch (status) {
    case STATUS_NAME.PUBLISHED:
      return STATUS.PUBLISHED;
    case STATUS_NAME.DRAFT:
      return STATUS.DRAFT;
  }
}
