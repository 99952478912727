/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';

import { Button, Preloader } from 'components/ui';
import { CollapsibleElement } from 'components/rfs-ui';

import css from './index.scss';

const cn = classnames.bind(css);

class FiltersContainer extends React.Component {
  static propTypes = {
    applyTitle: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    collapsible: PropTypes.bool,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    onApply: PropTypes.func,
    onReset: PropTypes.func,
    resetTitle: PropTypes.string,
  };

  static defaultProps = {
    applyTitle: 'Применить фильтры',
    children: null,
    className: undefined,
    collapsible: false,
    disabled: false,
    loading: false,
    onApply: undefined,
    onReset: undefined,
    resetTitle: 'Сбросить фильтры',
  };

  state = {
    isCollapsed: true,
  };

  toggleFiltersVisibility = () => this.setState((state) => ({ ...state, isCollapsed: !state.isCollapsed }));

  handleEnterPress = (event) => event.key === 'Enter' && this.handleApply();

  handleApply = () => {
    const { onApply } = this.props;
    onApply();
  };

  render() {
    const { isCollapsed } = this.state;
    const { children, onApply, onReset, collapsible, className, disabled, loading, applyTitle, resetTitle } = this.props;
    const showFilters = !collapsible || !isCollapsed;

    return (
      <div className={cn(css.container, className, 'filters-container-themed')} onKeyPress={this.handleEnterPress}>
        <CollapsibleElement collapsed={!showFilters}>
          <div className={css.filters}>{children}</div>
          {onApply && onReset && (
            <div className={cn(css.buttonsContainer, { withCollapse: collapsible })}>
              <Button onClick={this.handleApply} disabled={loading || disabled}>
                {loading ? <Preloader size="2x" /> : applyTitle}
              </Button>
              <Button type="secondary" onClick={onReset}>
                {resetTitle}
              </Button>
            </div>
          )}
        </CollapsibleElement>
        {collapsible && (
          <div className={css.collapseContainer}>
            <Button onClick={this.toggleFiltersVisibility} type="text" icon={isCollapsed ? 'chevron-down' : 'chevron-up'}>
              {isCollapsed ? 'Показать фильтры' : 'Свернуть фильтры'}
            </Button>
          </div>
        )}
      </div>
    );
  }
}

export default FiltersContainer;
