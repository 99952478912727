/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import { Field } from 'react-final-form';

import { isNumber, isFunction } from 'utils/lodash';

import DateTimePickerComponent from './DateTimePickerComponent';
import { PTField } from '../propTypes';

const DateTimePicker = (props) => {
  const { validate, required } = props;

  const validateField = useMemo(() => {
    if (required && !isFunction(validate)) {
      return (value) => !isNumber(value) && 'Обязательное поле';
    }

    return validate;
  }, [validate, required]);

  return <Field {...props} validate={validateField} component={DateTimePickerComponent} doValidate />;
};

DateTimePicker.propTypes = {
  ...DateTimePickerComponent.propTypes,
  ...PTField,
};

export default DateTimePicker;
