/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';

import { Async } from 'components/rfs-ui';
import { isFunction } from 'utils/lodash';

import { PTInput } from '../propTypes';

const AsyncComponent = ({ input, meta, onChange, ...props }) => {
  const handleChange = useCallback(
    (value) => {
      input.onChange(value);
      if (isFunction(onChange)) {
        onChange(value);
      }
    },
    [onChange, input],
  );

  return <Async {...props} onChange={handleChange} value={input.value || null} />;
};

AsyncComponent.propTypes = {
  ...Async.propTypes,
  ...PTInput,
};

export default AsyncComponent;
