import axios from 'axios';
import { ListItem, PageData } from '../propTypes/commonTypes';
import { ReleasesCardDto, ReleasesFilter, ReleasesItemDto } from '../propTypes/ReleasesType';

export const fetchReleasesRegistry = async(filter?: ReleasesFilter): Promise<{
  data: ReleasesItemDto[];
  pageData: PageData;
}> => {
  const response = await axios.get('/api/rest/releases/registry', {params: filter});
  return response.data;
}

export const fetchReleasesCard = async(releaseId: number): Promise<ReleasesCardDto> => {
  const response = await axios.get(`/api/rest/releases/${releaseId}`);
  return response.data.data;
}

export const editReleasesCard = async(payload: ReleasesCardDto) => {
  await axios.post('/api/rest/releases', payload);
}

export const deleteReleasesCard = async(releaseId: number) => {
  const response = await axios.delete(`/api/rest/releases/${releaseId}`);
  return response;
}

export const publishReleases = async(releaseId: number) => {
  const response = await axios.post(`/api/rest/releases/publish/${releaseId}`);
  return response.data.data;
}
