exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".dateRange-1NxZU {\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n}\n\n  .dateRange-1NxZU > *:first-child,\n  .dateRange-1NxZU > *:last-child {\n    -webkit-box-flex: 1;\n        -ms-flex: auto;\n            flex: auto;\n    margin-bottom: 0;\n  }\n\n  .dateRange-1NxZU .divider-1VYuw {\n    margin: 0 14px;\n  }\n", ""]);

// exports
exports.locals = {
	"dateRange": "dateRange-1NxZU",
	"divider": "divider-1VYuw"
};