/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Field } from 'react-final-form';

import SearchbarComponent from './SearchbarComponent';
import { PTField } from '../propTypes';

const Searchbar = (props) => <Field {...props} component={SearchbarComponent} />;

Searchbar.propTypes = {
  ...SearchbarComponent.propTypes,
  ...PTField,
};

export default Searchbar;
