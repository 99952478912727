/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Reference } from 'components/rfs-ui';
import { isFunction } from 'utils/lodash';

import { PTInput } from '../propTypes';

const ReferenceComponent = ({ input, meta, onChange, ...props }) => {
  const handleChange = useCallback(
    (data) => {
      input.onChange(data);
      if (isFunction(onChange)) {
        onChange(data);
      }
    },
    [onChange, input],
  );

  return <Reference {...props} onChange={handleChange} value={input.value} />;
};

ReferenceComponent.propTypes = {
  ...Reference.propTypes,
  ...PTInput,
  onChange: PropTypes.func,
};

ReferenceComponent.defaultProps = {
  onChange: undefined,
};

export default ReferenceComponent;
