import React, { ReactNode } from 'react';
import css from './FieldView.scss';

export const FieldView = ({ label, value, children }: {
  label: string;
  value?: string;
  children?: ReactNode;
}) => (
  <div className={css.wrField}>
    <span className={css.labelField}>{label}</span>
    {!!value && <span>{value}</span>}
    { children }
  </div>
);

