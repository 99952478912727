/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Field } from 'react-final-form';

import CheckboxComponent from './CheckboxComponent';
import { PTField } from '../propTypes';

const Checkbox = (props) => {
  const { id, name } = props;

  return <Field {...props} id={id || name} component={CheckboxComponent} />;
};

Checkbox.propTypes = {
  ...CheckboxComponent.propTypes,
  ...PTField,
};

export default Checkbox;
