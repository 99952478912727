/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { Avatar } from 'components/rfs-ui';
import { PTInput } from '../propTypes';

const AvatarComponent = ({ input, meta, ...props }) => <Avatar {...props} onChange={input.onChange} data={input.value || null} />;

AvatarComponent.propTypes = {
  ...Avatar.propTypes,
  ...PTInput,
};

export default AvatarComponent;
