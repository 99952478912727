exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* ------------------------\nCommon\n--------------------------*/\n\n/* layouts width*/\n\n/* ------------------------\nDark Theme\n--------------------------*/\n\n/* colors*/\n\n/* input*/\n\n/* modal content padding*/\n\n/* ------------------------\nLight Theme\n--------------------------*/\n\n/* colors*/\n\n/* modal content padding*/\n\n/* ------------------------\nRating\n--------------------------*/\n\n.wrField-zo3bR {\n  margin-bottom: 20px;\n}\n\n.labelField-25p9Z {\n  padding-right: 5px;\n  color: #39395b;\n  opacity: 0.4;\n  white-space: nowrap;\n}\n", ""]);

// exports
exports.locals = {
	"wrField": "wrField-zo3bR",
	"labelField": "labelField-25p9Z"
};