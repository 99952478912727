import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { DropdownH, Notification } from 'components/rfs-ui';
import { isEmpty } from 'utils/lodash';
import { pluralize } from 'magic/pluralize';

import { PTInput } from '../propTypes';
import ProfileCard from './ProfileCard';
import css from './Profiles.scss';

const Profiles = ({ input, meta, isLoading }) => {
  const [shouldShowNextError, setShouldShowNextError] = useState(true);
  const timeOutRef = useRef();
  const { value: profiles, onChange } = input;
  const { error } = meta;

  const handleDeleteProfile = useCallback(
    (id) => {
      if (profiles.length > 1) {
        setShouldShowNextError(false);
      }
      onChange(profiles.filter((prof) => prof.id !== id));
      setTimeout(() => setShouldShowNextError(true), 0);
    },
    [profiles, onChange],
  );

  const sortedProfiles = useMemo(() => {
    if (isEmpty(error)) {
      return profiles;
    }

    const { errors } = error;

    return [...profiles].sort((profileA, profileB) => {
      if (errors[profileA.id] && errors[profileB.id]) {
        return 0;
      }

      return errors[profileA.id] ? -1 : 1;
    });
  }, [profiles, error]);

  const userTitle = useMemo(() => {
    const firstWord = pluralize(profiles.length, ['Выбран', 'Выбрано', 'Выбрано']);
    const lastWord = pluralize(profiles.length, ['участник', 'участника', 'участников']);

    return `${firstWord} ${profiles.length} ${lastWord}`;
  }, [profiles.length]);

  useEffect(() => {
    // NOTE: To prevent showing too much messages
    if (!isEmpty(error) && error.message && !isLoading && shouldShowNextError) {
      timeOutRef.current = setTimeout(() => {
        Notification.error({
          children: {
            icon: 'user-times',
            message: error.message,
          },
        });
      }, 100);
    }

    return () => clearTimeout(timeOutRef.current);
  }, [error, isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DropdownH contentClassName={css.usersContainer} size={2} title={userTitle} line>
      {sortedProfiles.map(({ id, fio, rfsId, birthDate }) => (
        <ProfileCard
          key={id}
          id={id}
          fio={fio}
          rfsId={rfsId}
          birthDate={birthDate}
          onDelete={handleDeleteProfile}
          warn={error && error.errors[id]}
        />
      ))}
    </DropdownH>
  );
};

Profiles.propTypes = {
  ...PTInput,
  isLoading: PropTypes.bool,
};

Profiles.defaultProps = {
  isLoading: false,
};

export default Profiles;
