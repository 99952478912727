/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import dadataEndpoints from 'constants/dadataEndpoints';
import { addressRestructure } from 'utils/dadata';
import { isEmpty } from 'utils/lodash';

import DadataField from './DadataField';

const parseAddress = (value) => (isEmpty(value) ? null : addressRestructure(value));

const AddressField = (props) => <DadataField url={dadataEndpoints.address} parse={parseAddress} {...props} />;

export default AddressField;
