import React, { useState } from 'react';
import { makeFormattedDate } from 'magic/date';
import { FieldView } from '../../FieldView';
import { ButtonGroup } from 'components/ui';
import { ConfirmModal, TooltipButton } from 'components/rfs-ui';
import { ReleasesItemDto } from 'propTypes/ReleasesType';
import { STATUS_NAME } from 'constants/common';
import { makeStatus } from 'utils/status';
import css from '../ReleasesItem.scss';
import { deleteReleasesCard, publishReleases } from 'services/releases';
import { handleError } from 'magic/handlers';
import { AnyObject } from 'final-form';

interface ReleasesItemProp {
  data?: ReleasesItemDto;
  onEdit: () => void;
  onDelete: () => void;
  onPublish: () => void;
  isAccess: boolean;
}

export const ReleasesItemView = ({ data, onEdit, onDelete, onPublish, isAccess }: ReleasesItemProp) => {
  const {
    id, status, typeNames, releaseDate, releaseVersion, moduleNames,
    description
  } = data as ReleasesItemDto;
  const [isShowControl, setIsShowControl] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isOpenPublish, setIsOpenPublish] = useState(false);

  const handlePublish = async () => {
    try {
      await publishReleases(id);
      onPublish();
    } catch (error) {
      handleError(error as AnyObject);
    }
  }

  const handleEdit = () => {
    onEdit();
  }

  const handleDelete = async() => {
    try {
      await deleteReleasesCard(id);
      onDelete();
    } catch (error) {
      handleError(error as AnyObject);
    }
  }

  return (
    <>
      <div
        className={css.tile}
        onMouseEnter={() => setIsShowControl(true)}
        onMouseLeave={() => setIsShowControl(false)}
      >
        <div>
          <FieldView label="Статус:" value={makeStatus(status)} />
          <FieldView label="Тип новости:" value={typeNames} />
          <FieldView label="Дата релиза:" value={makeFormattedDate(releaseDate)} />
          <FieldView label="Версия релиза:" value={releaseVersion} />
          <FieldView label="Модуль:" value={moduleNames} />
          <FieldView label="Описание релиза:">
            <div className={css.body} dangerouslySetInnerHTML={{ __html: description }} />
          </FieldView>
        </div>

        <ButtonGroup className="btnGroupHover" right>
          {isAccess && isShowControl && (
            <>
              <TooltipButton.Delete onClick={() => setIsOpenDelete((prev) => !prev)} />
              <TooltipButton.Edit onClick={handleEdit} />
              {status === STATUS_NAME.DRAFT &&
                <TooltipButton.Publish onClick={() => setIsOpenPublish((prev) => !prev)} />
              }
            </>
          )}
        </ButtonGroup>
      </div>

      <ConfirmModal
        applyText="Удалить"
        isOpen={isOpenDelete}
        onApply={handleDelete}
        onClose={() => setIsOpenDelete((prev) => !prev)}
      >
        Вы действительно хотите удалить запись?
      </ConfirmModal>
      <ConfirmModal
        applyText="Опубликовать"
        isOpen={isOpenPublish}
        onApply={handlePublish}
        onClose={() => setIsOpenPublish((prev) => !prev)}
      >
        Вы действительно хотите опубликовать новость?
      </ConfirmModal>
    </>
  )
};
