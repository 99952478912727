import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { FormElement } from 'components/rfs-ui';
import { Input, Reference} from 'components/composed/Form';
import { DateRange } from 'components/composed/Form/DateTimePicker/DateRange';

export const FiltersFields = () => (
  <Row>
    <Col md={6}>
      <DateRange
        title="Дата релиза"
        nameFieldStart="dateStartFrom"
        nameFieldEnd="dateStartTo"
      />
    </Col>
    <Col md={6}>
      <FormElement title="Модуль">
        <Reference
          name="module"
          optionsPath="/api/rest/dictionary/releases/modules"
          labelKey="name"
          valueKey="id"
          clearable
          isMulti
        />
      </FormElement>
    </Col>
    <Col md={6}>
      <FormElement title="Тип новости">
        <Reference
          name="type"
          optionsPath="/api/rest/dictionary/releases/types"
          labelKey="name"
          valueKey="id"
          clearable
          isMulti
        />
      </FormElement>
    </Col>
    <Col md={6}>
      <FormElement title="Описание релиза">
        <Input name="description" />
      </FormElement>
    </Col>
  </Row>
);
