import PropTypes from 'prop-types';

const InputProps = {
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.any,
};

const MetaProps = {
  active: PropTypes.bool,
  dirty: PropTypes.bool,
  dirtySinceLastSubmit: PropTypes.bool,
  error: PropTypes.any,
  initial: PropTypes.any,
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  submitError: PropTypes.any,
  submitFailed: PropTypes.bool,
  submitSucceeded: PropTypes.bool,
  touched: PropTypes.bool,
  valid: PropTypes.bool,
  visited: PropTypes.bool,
};

export const PTInput = {
  input: PropTypes.shape(InputProps),
  meta: PropTypes.shape(MetaProps),
};

export const PTField = {
  afterSubmit: PropTypes.func,
  allowNull: PropTypes.bool,
  beforeSubmit: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  component: PropTypes.elementType,
  data: PropTypes.object,
  defaultValue: PropTypes.any,
  format: PropTypes.func,
  formatOnBlur: PropTypes.func,
  initialValue: PropTypes.any,
  isEqual: PropTypes.func,
  multiple: PropTypes.bool,
  name: PropTypes.string.isRequired,
  parse: PropTypes.func,
  ref: PropTypes.func,
  render: PropTypes.func,
  subscription: PropTypes.object,
  type: PropTypes.string,
  validate: PropTypes.func,
  validateFields: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.any,
};

export const PTForm = {
  active: PropTypes.string,
  dirty: PropTypes.bool,
  dirtyFields: PropTypes.object,
  dirtyFieldsSinceLastSubmit: PropTypes.object,
  dirtySinceLastSubmit: PropTypes.bool,
  error: PropTypes.any,
  errors: PropTypes.object,
  hasSubmitErrors: PropTypes.bool,
  hasValidationErrors: PropTypes.bool,
  initialValues: PropTypes.object,
  invalid: PropTypes.bool,
  modified: PropTypes.object,
  modifiedSinceLastSubmit: PropTypes.bool,
  pristine: PropTypes.bool,
  submitError: PropTypes.any,
  submitErrors: PropTypes.object,
  submitFailed: PropTypes.bool,
  submitSucceeded: PropTypes.bool,
  submitting: PropTypes.bool,
  touched: PropTypes.object,
  valid: PropTypes.bool,
  validating: PropTypes.bool,
  values: PropTypes.object,
  visited: PropTypes.object,
};
