/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { FileUpload } from 'components/rfs-ui';
import { PTInput } from '../propTypes';

const FileUploadComponent = ({ input, meta, inputType, inputMultiple, ...props }) => (
  <FileUpload {...props} type={inputType} multiple={inputMultiple} onChange={input.onChange} data={input.value} />
);

FileUploadComponent.propTypes = {
  ...FileUpload.propTypes,
  ...PTInput,
};

export default FileUploadComponent;
