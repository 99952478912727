/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import { Field } from 'react-final-form';
import { isNumber, isFunction } from 'utils/lodash';

import InputComponent from './InputComponent';
import { PTField } from '../propTypes';

/**
 * @type {React.FC<any>}
 */
const NumberInput = (props) => {
  const { validate, required } = props;

  const validateField = useMemo(() => {
    if (required && !isFunction(validate)) {
      return (value) => !isNumber(value) && 'Обязательное поле';
    }

    return validate;
  }, [validate, required]);

  return <Field {...props} validate={validateField} component={InputComponent} doValidate />;
};

NumberInput.propTypes = {
  ...InputComponent.propTypes,
  ...PTField,
};

export default NumberInput;
