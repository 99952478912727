/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';

import { Searchbar } from 'components/ui';
import { isFunction } from 'utils/lodash';
import { PTInput } from '../propTypes';

const SearchbarComponent = ({ input, meta, onChange, ...props }) => {
  const handleChange = useCallback(
    (value) => {
      input.onChange(value);
      if (isFunction(onChange)) {
        onChange(value);
      }
    },
    [onChange, input],
  );

  return <Searchbar {...props} {...input} onChange={handleChange} />;
};

SearchbarComponent.propTypes = {
  ...Searchbar.propTypes,
  ...PTInput,
};

export default SearchbarComponent;
