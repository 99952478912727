export const DEFAULT_PAGE = 0;
export const DEFAULT_PER_PAGE = 10;
export const ITEMS_COUNTER = [10, 20, 30, 40, 50];
export const ITEMS_COUNTER_EXT = [10, 20, 30, 40, 50, 100, 250, 500];

export const DEFAULT_PAGINATION = {
  itemsCount: null,
  page: DEFAULT_PAGE,
  pageCount: null,
  perPage: DEFAULT_PER_PAGE,
};
