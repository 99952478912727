/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';

import { Multiselect } from 'components/rfs-ui';
import { PTInput } from '../propTypes';

const MultiselectComponent = ({ input, meta, ...props }) => {
  const value = useMemo(() => (Array.isArray(input.value) ? input.value : []), [input.value]);

  return <Multiselect {...props} onChange={input.onChange} selected={value} />;
};

MultiselectComponent.propTypes = {
  ...Multiselect.propTypes,
  ...PTInput,
};

export default MultiselectComponent;
