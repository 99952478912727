import { isEmpty } from 'utils/lodash';

export const createProfilesValidator = () => {
  let lastProfiles;
  let lastSourceOrganization;
  let lastResult;

  return (profiles, { sourceOrganization }) => {
    if (profiles === lastProfiles && sourceOrganization === lastSourceOrganization) {
      return lastResult;
    }

    lastProfiles = profiles;
    lastSourceOrganization = sourceOrganization;

    const result = {};

    if (!profiles.length) {
      result.errors = {};
      result.message = 'Нет выбранных участников';
    } else {
      result.errors = profiles.reduce((obj, { id, error, orgInfo }) => {
        if (error) {
          return { ...obj, [id]: error };
        }

        if (orgInfo && sourceOrganization && !orgInfo.some(({ orgId }) => orgId === sourceOrganization.orgId)) {
          return { ...obj, [id]: 'Участник не состоит в выбранной организации' };
        }

        return obj;
      }, {});

      result.message = isEmpty(result.errors) ? undefined : 'Один или несколько участников не удовлетворяют требованиям';
    }

    lastResult = result.message ? result : undefined;
    return lastResult;
  };
};
