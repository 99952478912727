/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import ProfilesComponent from './Profiles';
import { createProfilesValidator } from './validateProfiles';

import { PTField } from '../propTypes';

const Profiles = ({ validate, ...props }) => {
  const validateProfiles = useMemo(() => {
    if (typeof validate === 'function') {
      return validate;
    }
    if (validate) {
      return createProfilesValidator();
    }

    return undefined;
  }, [validate]);

  return <Field {...props} validate={validateProfiles} component={ProfilesComponent} />;
}

Profiles.propTypes = {
  ...PTField,
  name: 'profiles',
  validate: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
};

Profiles.defaultProps = {
  name: 'profiles', // eslint-disable-line react/default-props-match-prop-types
  validate: undefined,
};

export default Profiles;
