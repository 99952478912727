import { isFunction, isNumber, get, isEmpty } from './lodash';

export const composeValidators = (...validators) => (...args) =>
  validators.reduce((acc, func) => acc || (isFunction(func) ? func(...args) : undefined), undefined);

export const requiredText = (value) => (value && String(value).trim() ? undefined : 'Обязательное поле');
export const requiredLengthText = (length) => (value) =>
  String(value || '').trim().length === length ? undefined : `Кол-во символов должно быть равно ${length}`;
export const requiredNumber = (value) => (value || value === 0 ? undefined : 'Обязательное поле');

export const createNonZeroLengthValidator = (message = 'Необходимо выбрать хотя бы один элемент') => (value) => {
  if (value === undefined || value === null) return message;
  return value?.length < 1 ? message : undefined;
};

export const onlyDigits = (value) => (!value || /^\d+$/.test(value) ? undefined : 'Может содержать только цифры');

export const requiredTextWithout = (key) => (value, allValues) => {
  const field = get(allValues, key);
  return field && String(field).trim() ? undefined : requiredText(value);
};

export const checkStringForCount = (targetCount) => (value) => {
  const string = value;
  if (targetCount.includes(String(string).length)) {
      return undefined;
  } else {
      return `Количество символов в строке должно быть равно ${targetCount.join(', ')}`;
  }
}

export const isInRange = (min, max) => (value) => {
  if (value >= min && value <= max) return undefined
  else return `Число должно входить в диапазон от ${min} до ${max}`
};

export const requiredDate = (value) => (isNumber(value) ? undefined : 'Обязательное поле');

export const createDateEndValidator = (dateStartKey, message = 'Дата окончания не может быть раньше даты начала') => (value, allValues) => {
  const dateStart = allValues[dateStartKey];
  return isNumber(dateStart) && isNumber(value) && dateStart > value ? message : undefined;
};

export const createDateStartValidator = (key, message = 'Дата начала не может превышать дату окончания', inclusive = false) => (
  value,
  allValues,
) => {
  const dateEnd = allValues[key];

  if (isNumber(dateEnd) && isNumber(value)) {
    const isValidDate = inclusive ? value >= dateEnd : value > dateEnd;
    return isValidDate ? message : undefined;
  }

  return undefined;
};

export const createCurrentDateValidator = (message = 'Не может превышать текущую дату') => (value) =>
  isNumber(value) && value > Date.now() ? message : undefined;

export const validateByMask = (mask) => (value) => {
  if (isEmpty(mask)) {
    return undefined;
  }

  if (!value && value !== 0) {
    return undefined;
  }

  const str = String(value);

  const hasError = mask.find((m, idx) => {
    if (m instanceof RegExp) {
      return !m.test(str[idx]);
    }

    return m !== str[idx];
  });

  return hasError ? 'Некорректное значение поля' : undefined;
};

export const validateBic = (value) => {
  if (!value) {
    return undefined;
  }

  const bic = String(value);

  if (/[^0-9]/.test(bic)) {
    return 'БИК может состоять только из цифр';
  }
  if (bic.length !== 9) {
    return 'БИК может состоять только из 9 цифр';
  }

  return undefined;
};

export const validateInn = (value) => {
  if (!value) {
    return undefined;
  }

  const inn = String(value);

  if (/[^0-9]/.test(inn)) {
    return 'ИНН может состоять только из цифр';
  }
  if (inn.length !== 10 && inn.length !== 12) {
    return 'ИНН может состоять только из 10 или 12 цифр';
  }

  const checkDigit = (val, coefficients) => {
    const result = coefficients.reduce((acc, coefficient, idx) => acc + coefficient * val[idx], 0);
    return parseInt((result % 11) % 10, 10);
  };

  if (inn.length === 10) {
    const n10 = checkDigit(inn, [2, 4, 10, 3, 5, 9, 4, 6, 8]);
    if (n10 === +inn[9]) {
      return undefined;
    }
  }

  if (inn.length === 12) {
    const n11 = checkDigit(inn, [7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
    const n12 = checkDigit(inn, [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);

    if (n11 === +inn[10] && n12 === +inn[11]) {
      return undefined;
    }
  }

  return 'Неправильное контрольное число';
};

export const validateSwift = (value) => {
  if (!value) {
    return undefined;
  }

  const swift = String(value);

  if (/[^0-9A-Z]/i.test(swift)) {
    return 'SWIFT может состоять только из цифр и букв латинского алфавита';
  }
  if (swift.length < 8 || swift.length > 11) {
    return 'SWIFT может состоять только из 8-11 цифр';
  }

  return undefined;
};

export const validateAccount = (value, { bank }) => {
  if (!value) {
    return undefined;
  }

  const account = String(value).replace(/[_\s]/g, '');
  const bic = bank && bank.bic ? String(bank.bic) : '';

  if (/[^0-9]/.test(account)) {
    return 'Расчетный счет может состоять только из цифр';
  }
  if (account.length !== 20) {
    return 'Расчетный счет может состоять только из 20 цифр';
  }
  const bicAccount = bic.slice(-3) + account;
  const coefficients = [7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1];
  const checksum = coefficients.reduce((acc, coefficient, idx) => acc + coefficient * (bicAccount[idx] % 10), 0);

  if (checksum && checksum % 10 !== 0) {
    return 'Неправильное контрольное число';
  }

  return undefined;
};

/**
 * Валидирует на соответствие email
 * @param email {string} - строка для проверки
 * @returns {undefined|string} - текст сообщения об ошибке
 */
export const validateEmail = (email) => {
  if (isEmpty(email)) {
    return undefined;
  }

  const regExp = /.+@.+\..{2,}/;

  return regExp.test(email) ? undefined : 'Некорректный email';
};

/**
 * Валидирует на соответствие адресу сайта
 * @param url {string} - строка для проверки
 * @returns {undefined|string} - текст сообщения об ошибке
 */
export const validateURL = (url) => {
  if (isEmpty(url)) {
    return undefined;
  }

  const regExp = /^(http(s)?:\/\/)?(www\.)?[a-zA-Zа-яА-Я0-9._-]{1,256}\.[a-zA-Zа-яА-Я0-9._-]{2,256}$/;

  return regExp.test(url) ? undefined : 'Некорректный адрес сайта';
};

/**
 * Валидирует на соответствие паттерну для номера телефона (плюс в начале и потом только цифры)
 * @param number {string} - строка для проверки
 * @returns {undefined|string} - текст сообщения об ошибке
 */
export const validateForeignPhoneNumber = (number) => {
  if (isEmpty(number)) {
    return undefined;
  }

  const regExp = /^\+\d+$/;

  return regExp.test(number) ? undefined : 'Некорректный номер телефона';
};

export const validateEngText = (text) => {
  const regExp = /^[A-Za-z0-9-.,:;!?"'\s]+$/;
  return regExp.test(text) ? undefined : 'Невалидные символы';
};

export const validateEngTextWithSymbols = (text) => {
  const regExp = /^[A-Za-z0-9-.,:;!?"'\s№@#&*_<>«»-]+$/;
  return regExp.test(text) ? undefined : 'Невалидные символы';
};


export const validateNotRequiredEngText = (text) => (text ? validateEngText(text) : undefined);

export const validateNotRequiredEngTextWithSymbols = (text) => (text ? validateEngTextWithSymbols(text) : undefined);

export const validateRuText = (text) => {
  const regExp = /^[А-ЯЁа-яё0-9-.,:;!?"'\s]+$/;
  return regExp.test(text) ? undefined : 'Невалидные символы';
};

export const validateRuTextWithSymbols = (text) => {
  const regExp = /^[А-ЯЁа-яё0-9-.,:;!?"'\s№@#&*_<>«»-]+$/;
  return regExp.test(text) ? undefined : 'Невалидные символы';
};

export const validateNotRequiredRuTextWithSymbols = (text) => (text ? validateRuTextWithSymbols(text) : undefined);

export const validateNotRequiredRuText = (text) => (text ? validateRuText(text) : undefined);

export const validateRuEngText = (text) => {
  const regExp = /^[0-9a-zA-Zа-яёА-ЯЁ.,:;!?"'\s]+$/;
  return regExp.test(text) ? undefined : 'Невалидные символы';
};

export const validateNotRequiredRuEngText = (text) => (text ? validateRuEngText(text) : undefined);
