import React from 'react';
import { isNumber } from 'utils/lodash';
import { FormElement } from 'components/rfs-ui';
import DateTimePicker from '../index';
import { createDateEndValidator } from 'utils/validators';
import css from './DateRange.module.scss';

export const DateRange = ({
  title,
  nameFieldStart = 'dateStart',
  nameFieldEnd = 'dateEnd',
  dateFrom,
  dateTo,
}: {
  title: string;
  nameFieldStart?: string;
  nameFieldEnd?: string;
  dateFrom?: number | string;
  dateTo?: number | string;
}) => {
  const disabledStart = isNumber(dateFrom) ? { before: new Date(dateFrom) } : undefined;
  const disabledEnd = isNumber(dateTo) ? { before: new Date(dateTo) } : undefined;

  return (
    <FormElement title={title}>
      <div className={css.dateRange}>
        <DateTimePicker
          name={nameFieldStart}
          disabledDays={disabledStart}
          placeholder="с"
        />
        <span className={css.divider}>&mdash;</span>
        <DateTimePicker
          name={nameFieldEnd}
          validate={createDateEndValidator(nameFieldStart)}
          disabledDays={disabledEnd}
          placeholder="по"
        />
      </div>
    </FormElement>
  );
}
