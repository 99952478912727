/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { PhotoUpload } from 'components/rfs-ui';
import { PTInput } from '../propTypes';

const PhotoUploadComponent = ({ input, meta, ...props }) => {
  const value = useMemo(() => (Array.isArray(input.value) ? input.value : []), [input.value]);

  return <PhotoUpload {...props} onChange={input.onChange} data={value} />;
};

PhotoUploadComponent.propTypes = {
  ...PhotoUpload.propTypes,
  ...PTInput,
  onChange: PropTypes.func,
};

PhotoUploadComponent.defaultProps = {
  onChange: undefined,
};

export default PhotoUploadComponent;
