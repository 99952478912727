/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Field } from 'react-final-form';

import AvatarComponent from './AvatarComponent';
import { PTField } from '../propTypes';

const Avatar = (props) => <Field {...props} component={AvatarComponent} />;

Avatar.propTypes = {
  ...AvatarComponent.propTypes,
  ...PTField,
};

export default Avatar;
