export const MODE = {
  VIEW: 'view',
  EDIT: 'edit',
  CREATE: 'create',
}

export const PageDataDefault = {
  page: 0,
  pagesize: 10,
}

export const STATUS_NAME = {
  PUBLISHED: 'PUBLISHED',
  DRAFT: 'DRAFT',
}
export const STATUS = {
  PUBLISHED: 'Опубликовано',
  DRAFT: 'Черновик',
}
